<template>
  <div id="formulario-edit-info">
    
    <vx-card style="height: calc(100vh - 100px);">
        <div style="position:absolute; height: calc(100vh - 180px); width: 66.666%; right:0px; z-index:2; top: 80px">                      
            <GoogleMapConcept :mapa="map" ref="gmc"></GoogleMapConcept>                      
        </div>  
        <div class="vx-row mb-4">
            <div class="vx-col w-1/3 flex">
                <div class="w-full">
                    <label class="vs-input--label">Estado</label>
                    <v-select                                               
                        :options="CONSTANTE_UFS" 
                        v-model="filtro.siglaUF"    
                        :clearable="false"
                        :reduce="r => r.data"                                                                         
                        class="vs-input--label w-full mr-2">
                    </v-select>  
                </div>
                <vs-input class="w-full ml-2" label="Nome" v-model="filtro.nomeMunicipio" name="nome" />                
            </div>   
            <div class="vx-col w-2/3 flex">
                <div class="w-full justify-center flex items-center">
                    <div class="w-full flex flex-start items-center mr-2"> 
                        <h5 v-if="municipioSelecionado" class="mr-4"> {{ this.municipioSelecionado.nomeUF }} </h5> <vs-input class="w-full" v-if="municipioSelecionado" v-model="municipioSelecionado.nomeMunicipio"  style='font-family: Verdana, sans-serif;' /> 
                    </div>
                    <div class="w-full"> 
                        <div class="btn-group">                    
                            <vs-button size="small" type="filled" v-if="coordenadasPerimetro.length > 0" @click="limparPerimetroNoMapa">LIMPAR PONTOS</vs-button>                                    
                            <vs-button size="small" type="filled" @click="centralizarNoPerimetro" v-if="coordenadasPerimetro.length > 0">CENTRALIZAR</vs-button>                                    
                            <vs-button class="ml-auto mt-2" size="small" @click="gravar" color="success" v-if="coordenadasPerimetro.length > 0">GRAVAR</vs-button>                                
                        </div>
                    </div>

                </div>                
            </div>                     
        </div>        
        <div class="vx-row mb-4">
            <div class="vx-col w-1/3">
                <div class="flex items-center"> 
                    <div class="btn-group">
                        <vs-button size="small" type="filled" color="primary" @click="fetchListaMunicipios">PESQUISAR</vs-button>                              
                        <vs-button size="small" type="filled" color="dark" @click="exibirPopUpPesquisarEndereco = true">LOCALIZAR ENDEREÇO</vs-button>                                    
                    </div>
                    <p class="ml-4" v-if="listaListaMunicipios && listaListaMunicipios.length"> [ {{ listaListaMunicipios.length }} ]</p>
                </div>
            </div>                
        </div>

        <div class="vx-row mb-4">
            <div class="vx-col w-1/3">
                <vs-table :data="listaListaMunicipios" noDataText="Nenhum Município" stripe hoverFlat class="tabelaComScroll">
                    <template slot="thead">              
                        <vs-th ></vs-th> 
                        <vs-th >UF</vs-th>
                        <vs-th >Município</vs-th>                                                  
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td class="p-0 "><vs-button size="small" icon="search" type="filled" color="dark" @click="carregarPerimetroMapa(tr)" class="mb-1"></vs-button> </vs-td>                 
                            <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.nomeUF}}</small></vs-td>                
                            <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.nomeMunicipio}}</small></vs-td>                                            
                            
                        </vs-tr>
                    </template>
                </vs-table>     
            </div>
        </div>
        
    </vx-card>  
    <vs-prompt @accept="localizarEndereco" title="Localizar Endereço" :active.sync="exibirPopUpPesquisarEndereco" acceptText="OK" cancelText="Fechar">
      <div>        
        <vs-input v-model="enderecoAPesquisar" class="mt-3 w-full" />
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import axios from "@/axios.js"
import { CONSTANTE_UFS } from '@/constantesComboBox.js'

export default {    
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
            return {nome: '', corEmHexa: '#ffffff', poligonoCercaJSON: []}
       }
    },
  },
  data() {
    return {    
        municipioSelecionado: null,      
        filtro: {siglaUF: 'AC', nomeMunicipio: null} , 
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),
        map: null,
        coordenadasPerimetro: [],  
        perimetroPath: null,
        exibirPopUpPesquisarEndereco: false,
        enderecoAPesquisar: null,
        marcadorLocalizacao: null,
        listaListaMunicipios: [],
        publicPath: process.env.BASE_URL         
    }
  },     
  created(){
      this.quebraPorZoom = [[],[],[],[],[]];
      this.CONSTANTE_UFS = CONSTANTE_UFS;
  }, 
  mounted() {        
        this.map = new window.google.maps.Map(document.getElementById('map'), {
            center: new window.google.maps.LatLng(-5, -42),       
            gestureHandling: 'greedy',
            zoom: 8,
            controlSize: 20,
            fullscreenControl:true,
            streetViewControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: window.google.maps.ControlPosition.LEFT_TOP
            }
        }); 
      
        let _self = this;
        window.google.maps.event.addListener(this.map, "click", function(event){            
            var latLong = new window.google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
            _self.adicionarMarkerPerimetro(latLong);                                
            _self.desenharPolygonPerimetro();
        });        
  },
  computed: {   
   
  },
  methods: { 
    gravar() {        
        if (this.formularioValido()) { 
            this.$vs.loading();
            const params = new URLSearchParams();        
            params.append('id', this.municipioSelecionado.id);   
            params.append('nomeMunicipio', this.municipioSelecionado.nomeMunicipio);      

            var primeiro = null;                  

            for(var j=0; j < this.coordenadasPerimetro.length; j++) {      
                
                params.append('novasCoordenadas', "" + this.coordenadasPerimetro[j].lng() + " " + this.coordenadasPerimetro[j].lat());
                if (j == 0) {
                    primeiro = this.coordenadasPerimetro[j];
                }
            }

            params.append('novasCoordenadas',""+ primeiro.lng() + " " + primeiro.lat());   
                                 
            axios.post("/AtualizarMunicipio", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/login' });
                } else {                    
                    if (response.data.mensagem.toLowerCase().indexOf("erro") != -1) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.mensagem, acceptText: 'OK'})
                    } else if (response.data.mensagem.toLowerCase().indexOf("erro") === -1) {
                        this.$vs.dialog({ color: 'success', title: 'Aviso', text: response.data.mensagem, acceptText: 'OK'})
                        this.fetchListaMunicipios();
                    }                                
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }
         
    },    
    formularioValido() {            
        if (!this.municipioSelecionado) { 
            this.$vs.dialog({color:'danger', title: 'Atenção', text: 'Selecione um município.', acceptText:'OK' })         
            return false;
        }

        if (!this.municipioSelecionado.nomeMunicipio) { 
            this.$vs.dialog({color:'danger', title: 'Atenção', text: 'Informe o nome do município.', acceptText:'OK' })         
            return false;
        }

        if (!this.coordenadasPerimetro || this.coordenadasPerimetro.length < 3) { 
            this.$vs.dialog({color:'danger', title: 'Atenção', text: 'Informe o polígono do município.', acceptText:'OK' })         
            return false;
        }
    
        return true;
    },    
    localizarEndereco() {            
            let vQueryString = 'format=json&q='+ this.enderecoAPesquisar;
            vQueryString = vQueryString + '&addressdetails=1&accept-language=pt&limit=1';

            axios.post('https://nominatim2.concept.inf.br/nominatim/search?' + vQueryString, null, {              
                    responseEncoding: 'iso88591', withCredentials: false } )
            .then((response) => {
                if (response.data && response.data.length > 0) {                    
                    var latLong = new window.google.maps.LatLng(parseFloat(response.data[0].lat), parseFloat(response.data[0].lon));                         
                    this.map.setCenter(latLong);
                    if (this.marcadorLocalizacao != null) {
                        this.marcadorLocalizacao.setMap(null);
                        this.marcadorLocalizacao = null;
                    }
                    this.marcadorLocalizacao = new window.google.maps.Marker({
                        position: latLong,
                        icon: { url:'./../localizacaoEndereco.png'},
                        map: this.map });

                } else {
                    this.$vs.dialog({ color: 'danger', title: 'Atenção', text: 'Endereço não encontrado', acceptText: 'OK'})
                }
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - localizarEndereco - " + error); 
            })  
    },
    adicionarMarkerPerimetro(latLong) {
        var iconePerimetro =  this.publicPath + "imagens/markerPerimetro.png";
        var ultimoMarcador = this.$refs.gmc.addMarkerDraggable(latLong, this.map, iconePerimetro);
        let _self = this;

        window.google.maps.event.addListener(ultimoMarcador, 'dragend', function() {        
            _self.desenharPolygonPerimetro();             
        });
        return ultimoMarcador;
    },  
    desenharPolygonPerimetro() {
        if (this.perimetroPath != null ) {
            this.perimetroPath.setMap(null);
            this.coordenadasPerimetro = [];
        }

        var marcadores = this.$refs.gmc.getMarcadores();

        for(var i=0; i < marcadores.length;i++) {                                                    
            this.coordenadasPerimetro.push(marcadores[i].getPosition());            
        }

        if ( marcadores.length > 0 && marcadores.length > 2) {                           

            this.perimetroPath = new window.google.maps.Polygon({
                path: this.coordenadasPerimetro,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                fillColor: this.data_local.corEmHexa,
                fillOpacity: 0.15            
            });
        
            this.perimetroPath.setMap(this.map);            
        }
    }, 
    centralizarNoPerimetro() {
        var marcadores = this.$refs.gmc.getMarcadores();
        if (marcadores && marcadores.length > 0) {
            var boundPolygon = new window.google.maps.LatLngBounds();
            
            for(var i=0; i < marcadores.length;i++) {                                                                
                boundPolygon.extend(marcadores[i].getPosition());
            }

            this.map.setCenter(boundPolygon.getCenter());
            this.map.fitBounds(boundPolygon);
            this.zoomStart = this.map.getZoom();
        }
    },
    limparPerimetroNoMapa() {
        this.$refs.gmc.deleteMarkers();
        this.coordenadasPerimetro = null;
        this.coordenadasPerimetro = [];
        
        if (this.perimetroPath !== null) {              
              this.perimetroPath.setMap(null);                
        } 
    },   
    carregarPerimetroMapa(tr) {  
        this.limparPerimetroNoMapa();
        this.$refs.gmc.deleteMarkers();

        if (this.perimetroPath !== null) {
              this.coordenadasPerimetro = [];
              this.perimetroPath.setMap(null);  
        } 

        var listaPontos = tr.poligonoCercaJSON;
        var latLong = null;            
        
        for(var i=0; i < (listaPontos.length-1); i++) {   
            latLong = new window.google.maps.LatLng(parseFloat(listaPontos[i].lat), parseFloat(listaPontos[i].long));                                                
            this.adicionarMarkerPerimetro(latLong);
        }
        this.desenharPolygonPerimetro();   
        this.centralizarNoPerimetro();   
        this.municipioSelecionado = tr;     
    },  
    fetchListaMunicipios(){
        this.$vs.loading();
        const params = new URLSearchParams();
        params.append('method', 'ListarVeiculosDoPerimetro');        
        params.append('siglaUF', this.filtro.siglaUF);   
        if (this.filtro.nomeMunicipio) {
            params.append('nomeMunicipio', this.filtro.nomeMunicipio);        
        }
        axios.post('/ListarPolygonoMunicipio', params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/login' });
            } else {
                this.listaListaMunicipios = response.data;                                       
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - fetchVeiculosAssociados - " + error); 
        })   
    } 
  }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 260px);
      position: relative;      
    } 
</style>